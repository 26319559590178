<template>
  <div class="my-content">
    <div>
        <div class="avatar-box" @click="loginFn">
          <div class="avatar-img-box">
            <div class="avatar-img">
              <img
                v-if="userInfo.userHeadimgurl"
                :src="userInfo.userHeadimgurl"
                alt="头像"
                class="avatar-img-img"
              />
              <svg-icon
                v-else
                icon-class="avatar"
                className="avatar-svg avatar-img-img"
              />
            </div>
            <div class="avatar-name" v-if="userInfo.token">{{ userInfo.userNickname || '' }}</div>
            <div class="avatar-name" v-else>立即登录</div>
          </div>
        </div>

        <div class="bless-box">
          <!-- 订单祈福 -->
          <div class="bless-order" @click="gotoOrderListFn">
            <div>
              <div>
                <svg-icon icon-class="order" class="dingdan-img"></svg-icon>
<!--                <img :src="dingdan" alt="" class="dingdan-img" />-->
              </div>
              <span>祈福订单</span>
            </div>
          </div>
          <!-- 我的寺院 -->
          <div class="bless-mine" @click="goToTempleListFn">
            <div>
              <div>
                <svg-icon icon-class="pavilion"></svg-icon>
<!--                <img :src="siyuan" alt="" class="siyuan-img" />-->
              </div>
              <span>关注寺院</span>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="my-cell">
            <van-cell title="佛历日历" is-link to="/my/calendar"/>
            <van-cell title="用户反馈" is-link to="/my/option"/>
            <van-cell title="系统设置" is-link to=""/>
            <van-cell class="exitLogin" title="退出登录" v-show="userInfo.token" @click="exitLogin"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapMutations, mapState } from 'vuex'
import { getQueryParam } from '@/utils/url'
import { userWxLogin } from '@/api/modules/login'

export default {
  name:'MyIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      isShow: false,
      code: getQueryParam('code')
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('user',['userInfo']),
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('user',['setUserInfo']),
    gotoOrderListFn(){
      return
       //this.$router.push('/my/orderlist')
    },
    goToTempleListFn(){
      return
     // this.$router.push('/my/templelist')
    },
    exitLogin(){
      this.$dialog.confirm({
        title: '确认退出登录？',
      })
        .then(() => {
          this.setUserInfo({})
          this.$router.push("/login")
        })
        .catch(() => {
          return
        });
    },
    loginFn(){
      if(!this.userInfo.token){
        this.$router.push("/login")
      }
    },
    wxLogin(){
      // 获取 code 的 URL
      //const res = await store.dispatch('queryCode', path);
      const appid = 'wxa488b1eb0254af21'
      // 拼接协议加域名，包括冒号和双斜杠
      // alert(window.location.protocol)  //协议  https:
      // alert(window.location.host)      //域名  recept.zjbbkj.com
      // alert(window.location.origin)    //https://recept.zjbbkj.com
      // alert()    //
      //const protocol = window.location.protocol; // 获取协议，如 "http:" 或 "https:"
      //const host = window.location.host;
      const redirect_url = window.location.href
      const red_url = encodeURIComponent(redirect_url)
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid
         + "&redirect_uri=" + red_url + "&response_type=code&scope=snsapi_userinfo" + "&state=STATE" + "#wechat_redirect"; //+ "state=" + path + "&forcePopup=true"
    },

  },
//  生命周期 -创建完成（可以访问当前this 实例）
  async created () {
    const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
    //判断是否是微信环境
    if(isWeChat && this.userInfo.token && !this.userInfo.userHeadimgurl && !this.userInfo.userNickname){
      if(!this.code){
        this.wxLogin()
      }else {
        //拿到code然后获取头像和昵称
        const res = await userWxLogin(this.code);
        this.setUserInfo(res.data)
      }
    }
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.my-content{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  .avatar-box {
    position: relative;
    width: 375px;
    height: 223px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: url("~@/assets/banner.png");
    background-color: #972923;
    background-size: cover;
    .avatar-img-box{
      .avatar-img{
        width: 76px;
        height: 76px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 50%;
        overflow: hidden;
      }
      .avatar-name{
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC sans-serif;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        margin-top: 10px;
      }
    }
  }
  .bless-box{
    position: absolute;
    left: 15px;
    top: 188px;
    width: 344px;
    height: 86px;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(188, 141, 74, 0.3);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bless-order{
      width: 171px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-right: solid 1px #aaa;
      box-sizing: border-box;
      .dingdan-img{
        width: 23px;
      }
    }
    .bless-mine{
      width: 171px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      .siyuan-img{
        width: 23px;
      }
    }
  }
  .cell-box{
    height: 500px;
    margin: 70px 10px 0;
    .my-cell {
      margin-top: 10px;
      .exitLogin {
        margin-top: 10px;
        text-align: center;
        color: #d86262;
        font-size: 18px;
      }
    }
  }
}

.svg-icon{
  width: 24px;
  height: 24px;
}

.avatar-img-img{
  width: 100%;
  height: 100%;

}

</style>
